import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const itemsGridBlocks = document.querySelectorAll('.items-grid-block');

  if (0 < itemsGridBlocks.length) {
    itemsGridBlocks.forEach((itemsGridBlock) => {
      new Swiper(itemsGridBlock.querySelector('.swiper'), {
        slidesPerView: 1.2,
        spaceBetween: 20,
        watchOverflow: true,

        navigation: {
          prevEl: itemsGridBlock.querySelector('.swiper-button-prev'),
          nextEl: itemsGridBlock.querySelector('.swiper-button-next'),
        },

        breakpoints: {
          560: {
            slidesPerView: 1.6,
          },
          680: {
            slidesPerView: 2.1,
          },
          1080: {
            slidesPerView: 2.7,
          },
          1400: {
            slidesPerView: 3,
          },
          1700: {
            slidesPerView: 3,
          },
        },
      });
    });
  }
});
